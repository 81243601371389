import React from 'react'
import {Link} from 'react-router-dom'
import Breadcumb from '../Breadcumb/Main'
import BgImg from  "../../assets/images/blog/careers.png"

const Careers = () => {
    return (
        <>
            <Breadcumb Title="Careers" Breadcumb="Careers" BgImg={BgImg} />
            <section className="section-padding--top section-padding--bottom">
                <div className="container">

                    <h3 className="blog-card-one__title">We are NOT Hiring at the moment</h3>
                    {/*<div className="row gutter-y-30">*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">UI/UX</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior Graphic*/}
                    {/*                    designer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Mobile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior Android*/}
                    {/*                    Engineer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">SEO</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">SEO Expert</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Mobile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior iOS*/}
                    {/*                    Engineer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Agile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior Scrum*/}
                    {/*                    Master</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Agile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior Product*/}
                    {/*                    Owner</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">UI/UX</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Intern Graphic*/}
                    {/*                    Designer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Mobile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Intern Android*/}
                    {/*                    Engineer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Mobile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Intern iOS Engineer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Agile</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Intern Scrum Master</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Management</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Office Manager</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">*/}
                    {/*        <div className="blog-card-one">*/}
                    {/*            <div className="blog-card-one__content">*/}
                    {/*                <div className="blog-card-one__meta">*/}
                    {/*                    <div className="blog-card-one__date">*/}
                    {/*                        <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                    {/*                        July, 25, 2022*/}
                    {/*                    </div>*/}
                    {/*                    <Link to="/blog" className="blog-card-one__category">Backend</Link>*/}
                    {/*                </div>*/}
                    {/*                <h3 className="blog-card-one__title"><Link to="/blog-details">Senior Java Engineer</Link></h3>*/}
                    {/*                <Link to="/blog-details" className="blog-card-one__more">*/}
                    {/*                    Read More*/}
                    {/*                    <i className="fa fa-arrow-right"></i>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>
        </>
    )
}

export default Careers;
