import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import BgImg from "../../../assets/images/devops.jpg";

const DevOps = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nulla eu nisi pellentesque, ultrices lorem eget, mattis dolo",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Praesent nec ante sed libero tempus rutrum",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Integer et mi in eros commodo bibendum",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Proin commodo turpis eu leo tempus varius",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        }
    ]
    return (
        <>
            <Breadcumb Title="DevOps" Breadcumb="SERVICES" BgImg={BgImg} Extra={{backgroundPosition: 'center center'}}/>
            <section className="section-padding--bottom section-padding--top service-details--page">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-8">
                            <div className="service-details__image">
                                <img src="assets/images/services/service-d-1.jpg" alt="" />
                            </div>
                            <h3 className="service-details__title">DevOps & CI/CD</h3>
                            <div className="service-details__content">
                                <p>An important part of the implementation of a solution is to be sustainable in the long term. Our consultants have experience not only in working in DevOps groupings, but also in developing CI/CD solutions and in improving working methods to suit organization and technical challenges. Safety conscious with respect for the balance between cost, time and quality.</p>
                                <h4>When an unknown printer took a galley of type and scrambled it to make a type specimen
                                    book.</h4>

                                <ul className="service-details__caption__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Implementation and management of CI/CD solutions
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Establish or lead DevOps teams
                                    </li>
                                </ul>

                                <div className="service-details__content"/>

                                <p>
                                    Consulting roles: DevOps developer, scrum master, software or solution architect
                                </p>
                            </div>
                            {/*<div className="row gutter-y-30 service-details__box-wrapper">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-consulting"></i>*/}
                            {/*            <div className="service-details__box__content">*/}

                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Consultant</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-chip"></i>*/}
                            {/*            <div className="service-details__box__content">*/}
                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Specialist</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row gutter-y-30">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <img src="assets/images/services/service-d-2.jpg" className="service-details__sub-image"*/}
                            {/*             alt="" />*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__caption">*/}
                            {/*            <h3 className="service-details__caption__title">Our Benefits</h3>*/}
                            {/*            <p className="service-details__caption__text">Duis aute irure dolor in reprehenderit in*/}
                            {/*                voluptate velit esse cillum.</p>*/}
                            {/*            <ul className="service-details__caption__list">*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Praesent efficitur quam sit amet*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Nunc cursus dolor id purus euismod*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Quisque tincidunt eros ac place viverra*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="row">*/}
                            {/*    <div className="col-md-12">*/}
                            {/*        <div className="accrodion-grp service-details__accrodion"*/}
                            {/*             data-grp-name="service-details__accrodion-1">*/}
                            {/*            {faqs.map((item, index) => (*/}
                            {/*                <div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">*/}
                            {/*                    <div className="accrodion-title">*/}
                            {/*                        <h4 onClick={() => setClicked(index)}>{item.question}<span*/}
                            {/*                            className="accrodion-icon"></span>*/}
                            {/*                        </h4>*/}
                            {/*                    </div>*/}
                            {/*                    {index === clicked &&*/}
                            {/*                        <div className="accrodion-content">*/}
                            {/*                            <div className="inner">*/}
                            {/*                                <p>{item.answer}</p>*/}
                            {/*                            </div>*/}
                            {/*                        </div>}*/}
                            {/*                </div>*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-lg-4 sidebar-column">
                            <ServiceSidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default DevOps