import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact-one-bg-1-1.jpg'
import Breadcumb from '../Breadcumb/Main'
import BgImg from  "../../assets/images/contact.jpg"
import axios from 'axios';

const Contact = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const onSubmit = (e) => {
       e.preventDefault()

        const data = {
            clientEmail: email,
            subject: name,
            content: message
        }

        axios.post(`https://emailapi-production.up.railway.app/v1/mailing`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setName("")
            setEmail("")
            setMessage("")
        })
    }

    return (
        <>
        <Breadcumb Title="Contact" Breadcumb="CONTACT" BgImg={BgImg}/>
            <section className="contact-one section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <form onSubmit={onSubmit} className="contact-one__form contact-form-validated">
                                <div className="section-title">
                                    <p className="section-title__text">Contact with us</p>
                                    <h2 className="section-title__title">We are always ready for good business.</h2>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12">
                                        <input value={name} type="text" placeholder="Your name" name="name" onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input value={email} type="email" placeholder="Email address" name="email" onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <textarea value={message} name="message" placeholder="Write message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="thm-btn contact-one__btn" type="submit"><span>send a
                                            message</span></button>
                                    </div>
                                </div>
                            </form>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms"
                                style={{backgroundImage: `url(${BG})`}}>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Our Offices</h3>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">United Kingdom</h3>
                                    <p className="contact-one__item__text">60 Barr Street, B19 3DX - Birmingham <br />
                                        United Kingdom (UK)</p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Phone</h3>
                                    <p className="contact-one__item__text">Local: <Link to="tel:222999888">222 999 888</Link><br />
                                        Mobile: <Link to="tel:0008888999">000 8888 999</Link></p>
                                </div>

                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Email</h3>
                                    <p className="contact-one__item__text"><Link
                                        to="mailto:needhelp@company.com">support@beettechnologies.co.uk</Link><br /><Link
                                        to="mailto:inquiry@company.com">inquiry@beettechnologies.co.uk</Link></p>
                                </div>
                                <div className="contact-one__item">
                                    <ul className="contact-one__social">
                                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="google-map google-map--contact">
                <iframe
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=60%20Barr%20Street+(BEET%20Technologies%20Headquarters)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    className="google-map__one" title='gmap' allowFullScreen></iframe>

            </section>
        </>
    )
}

export default Contact