import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import BgImg from "../../../assets/images/software-development.jpg";
import project from "../../../assets/images/project.jpg"

const SoftwareDevelopment = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "API & middleware development",
            answer: "Seamlessly integrate your apps - streamline your data exchange, enhance your interoperability, and optimise your performance. By crafting the interfaces and intermediary connections that facilitate effective communication between the different software applications in your ecosystem."
        },
        {
            question: "Solution architecture & microservices",
            answer: "Ensure that your complex software systems are both performant and maintainable by architecting your back-end systems into small, specialised, loosely coupled services using DevOps principles. Each operating independently and focused on a specific function in order to foster agility, scalability, and pace of development and deployment. "
        },
        {
            question: "Quality assurance",
            answer: "Implement systematic quality assurance processes to ensure that your products and platforms meet defined standards as well as user requirements. We test, validate, and verify to identify issues early in the development cycle. Ensuring quality and reliability alongside speed of delivery."
        },
        {
            question: "Prototyping & design",
            answer: "Ensure that your products are aligned with both stakeholder and customer needs. We create both visual and functional mockups to illustrate and demonstrate how your final product will look and behave. This involves building interactive prototypes early in the development cycle, so that we can test ideas and gather user feedback."
        },
        {
            question: "User interface design & build",
            answer: "Craft user interfaces that balance aesthetics, usability, and accessibility to maximise user experience and customer satisfaction. We’re committed to effectively turning innovative design concepts into functional, engaging digital products that will make a remarkable impact and delight your customers."
        },
        {
            question: "Websites & web apps",
            answer: "Develop remarkable digital products for web browsers. Whether you’re looking for a full website design and build, or the creation of a web app to deliver a dynamic, interactive experience. Our breadth and depth of experience across programming languages and tech stack means we can deliver at pace."
        },
        {
            question: "E-commerce",
            answer: "Design and build online e-commerce platforms that are secure by design, user-friendly, and integrate easily with your preferred payment gateways, shopping carts, product information management (PIM), and content management systems (CMS). Providing a seamless, enjoyable shopping experience for your customers."
        },
    ]
    return (
        <>
            <Breadcumb Title="Software Engineering" Breadcumb="SERVICES" BgImg={BgImg} Extra={{backgroundPosition: 'center center'}}/>
            <section className="section-padding--bottom section-padding--top service-details--page">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-8">
                            <div className="service-details__image">
                                <img src={project} alt="" />
                            </div>
                            <h3 className="service-details__title">What is software engineering?</h3>
                            <div className="service-details__content">
                                <p>Software engineering lies at the heart of digital product development, encompassing server-side infrastructure, databases, and application logic to ensure efficient data processing, security, and scalability in response to user demands. Our approach follows MACH principles - (Microservices, API-first, Cloud-native, and Headless) - emphasising flexibility and scalability. We strive for seamless, intuitive user interfaces, balancing functionality and appeal, and guaranteeing a consistent, remarkable customer experience across all devices through a user-centric approach to development.</p>
                            </div>
                            <h3 className="service-details__title">The problems we solve</h3>
                            {/*<div className="row gutter-y-30 service-details__box-wrapper">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-consulting"></i>*/}
                            {/*            <div className="service-details__box__content">*/}

                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Consultant</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-chip"></i>*/}
                            {/*            <div className="service-details__box__content">*/}
                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Specialist</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row gutter-y-30">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <img src="assets/images/services/service-d-2.jpg" className="service-details__sub-image"*/}
                            {/*             alt="" />*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__caption">*/}
                            {/*            <h3 className="service-details__caption__title">Our Benefits</h3>*/}
                            {/*            <p className="service-details__caption__text">Duis aute irure dolor in reprehenderit in*/}
                            {/*                voluptate velit esse cillum.</p>*/}
                            {/*            <ul className="service-details__caption__list">*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Praesent efficitur quam sit amet*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Nunc cursus dolor id purus euismod*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Quisque tincidunt eros ac place viverra*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="accrodion-grp service-details__accrodion"
                                         data-grp-name="service-details__accrodion-1">
                                        {faqs.map((item, index) => (
                                            <div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                <div className="accrodion-title">
                                                    <h4 onClick={() => setClicked(index)}>{item.question}<span
                                                        className="accrodion-icon"></span>
                                                    </h4>
                                                </div>
                                                {index === clicked &&
                                                    <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 sidebar-column">
                            <ServiceSidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default SoftwareDevelopment