import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'

import BgImg from  "../../../assets/images/mobile-app.jpg"
import Mobile from  "../../../assets/images/qa.jpg"



const MobileApplication = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nulla eu nisi pellentesque, ultrices lorem eget, mattis dolo",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Praesent nec ante sed libero tempus rutrum",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Integer et mi in eros commodo bibendum",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        },
        {
            question: "Proin commodo turpis eu leo tempus varius",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
        }
    ]
    return (
        <>
            <Breadcumb Title="Mobile App Development" Breadcumb="SERVICES" BgImg={BgImg} Extra={{backgroundPosition: 'center center'}}/>
            <section className="section-padding--bottom section-padding--top service-details--page">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-8">
                            <div className="service-details__image">
                                <img src={Mobile} alt="" />
                            </div>
                            <h3 className="service-details__title">Mobile Application & Development</h3>
                            <div className="service-details__content">
                                <p>In the fast-paced world of mobile applications, staying ahead of the competition is vital. Our app development services ensure that your business remains relevant and accessible to your target audience. Whether you need a native app for iOS or Android or a cross-platform solution, we have the expertise to deliver outstanding results.</p>
                                <h4>Why Choose BEET Technologies for App Development</h4>

                                <ul className="service-details__caption__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Scalability and Performance: Our apps are built to handle increasing user demands and deliver optimal performance even under heavy usage.
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Maintenance and Updates: We offer continuous support and updates to keep your app up-to-date and functioning flawlessly.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Security: We understand the importance of data security and implement robust measures to safeguard user information and maintain the confidentiality of your app’s data. • Integration: Our app development solutions seamlessly integrate with your existing systems or platforms, ensuring a smooth user experience and efficient workflows.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Agile Development: We follow an agile development methodology, allowing for flexibility, faster delivery, and the ability to adapt to changing market trends and user requirements.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Experienced Team: Our team of app developers is well-versed in the latest technologies and trends in mobile app development, ensuring that your app stays ahead of the curve. • Proven Track Record: Over the years, we have successfully delivered numerous mobile app projects for clients from various industries, garnering positive feedback and repeat business.
                                    </li>


                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}


                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Let Veritaz be your partner in developing an exceptional mobile app that caters to your business needs and delights your users. Contact us today to get started on your mobile app development journey.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        UI/UX Design: Our design team creates wireframes and prototypes to visualize the app’s user interface and experience, seeking your feedback for improvements.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        App Development: Our skilled developers bring your app to life, using the latest tools and technologies to create a robust and feature-rich application.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Testing: We conduct rigorous testing to identify and resolve any bugs or performance issues, ensuring a smooth and error-free user experience.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Deployment: Once the app is thoroughly tested and approved, we handle the deployment process to make it available to your users through the respective app stores.
                                    </li>

                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Support and Maintenance: We provide ongoing support, updates, and maintenance to keep your app running smoothly and to incorporate any future enhancements.
                                    </li>

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}

                                    {/*<li>*/}
                                    {/*    <i className="fa fa-check-circle"></i>*/}

                                    {/*</li>*/}
                                </ul>
                            </div>
                            {/*<div className="row gutter-y-30 service-details__box-wrapper">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-consulting"></i>*/}
                            {/*            <div className="service-details__box__content">*/}

                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Consultant</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__box">*/}
                            {/*            <i className="service-details__box__icon icon-chip"></i>*/}
                            {/*            <div className="service-details__box__content">*/}
                            {/*                <h3 className="service-details__box__title">*/}
                            {/*                    <Link to="#">IT Specialist</Link>*/}
                            {/*                </h3>*/}
                            {/*                <p className="service-details__box__text">There are many of of lorem Ipsum, but the*/}
                            {/*                    majori have suffered.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row gutter-y-30">*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <img src="assets/images/services/service-d-2.jpg" className="service-details__sub-image"*/}
                            {/*             alt="" />*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-6 col-sm-12">*/}
                            {/*        <div className="service-details__caption">*/}
                            {/*            <h3 className="service-details__caption__title">Our Benefits</h3>*/}
                            {/*            <p className="service-details__caption__text">Duis aute irure dolor in reprehenderit in*/}
                            {/*                voluptate velit esse cillum.</p>*/}
                            {/*            <ul className="service-details__caption__list">*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Praesent efficitur quam sit amet*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Nunc cursus dolor id purus euismod*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <i className="fa fa-check-circle"></i>*/}
                            {/*                    Quisque tincidunt eros ac place viverra*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="row">*/}
                            {/*    <div className="col-md-12">*/}
                            {/*        <div className="accrodion-grp service-details__accrodion"*/}
                            {/*             data-grp-name="service-details__accrodion-1">*/}
                            {/*            {faqs.map((item, index) => (*/}
                            {/*                <div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">*/}
                            {/*                    <div className="accrodion-title">*/}
                            {/*                        <h4 onClick={() => setClicked(index)}>{item.question}<span*/}
                            {/*                            className="accrodion-icon"></span>*/}
                            {/*                        </h4>*/}
                            {/*                    </div>*/}
                            {/*                    {index === clicked &&*/}
                            {/*                        <div className="accrodion-content">*/}
                            {/*                            <div className="inner">*/}
                            {/*                                <p>{item.answer}</p>*/}
                            {/*                            </div>*/}
                            {/*                        </div>}*/}
                            {/*                </div>*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-lg-4 sidebar-column">
                            <ServiceSidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default MobileApplication