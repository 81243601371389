import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import About from '../components/About/Main'
import BlogDetails from '../components/Blog/BlogDetails/Main'
import Blogs from '../components/Blog/Blogs/Main'
import Contact from '../components/Contact/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Navbar from '../components/Navbar/Main'
import ProjectDetails from '../components/Project/ProjectDetails/Main'
import Projects from '../components/Project/Projects/Main'
import CyberSecurity from '../components/Services/CyberSecurity/Main'
import InfrastructurePlan from '../components/Services/InfrastructurePlan/Main'
import ITConsultant from '../components/Services/ITConsultant/Main'
import ITManagement from '../components/Services/ITManagement/Main'
import QATesting from '../components/Services/QATesting/Main'
import Team from '../components/Team/Main'
import MobileApplication from '../components/Services/MobileApplication/Main'
import SoftwareDevelopment from '../components/Services/SoftwareDevelopment/Main'
import DevOps from '../components/Services/DevOps/Main'
import AgileMethods from '../components/Services/AgileMethods/Main'
import Careers from "../components/Careers/Main";

const Routing = () => {
    const [homePage, setHomePage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/home-02" || location.pathname === "/home-03") {
          setHomePage(false)
      }else{
          setHomePage(true)
      }
    }, [location])
    
    return (
        <>
            {homePage && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/services" element={<ITConsultant />} />
                <Route path="/cyber-security" element={<CyberSecurity />} />
                <Route path="/it-management" element={<ITManagement />} />
                <Route path="/mobile-application-development" element={<MobileApplication />} />
                <Route path="/software-engineering" element={<SoftwareDevelopment />} />
                <Route path="/devops" element={<DevOps />} />
                <Route path="/agile-methodologies" element={<AgileMethods />} />
                <Route path="/qa-testing" element={<QATesting />} />
                <Route path="/infrastructure-plan" element={<InfrastructurePlan />} />
                <Route path="/it-consultant" element={<ITConsultant />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/job-details" element={<ProjectDetails />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
        </>
    )
}

export default Routing