import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import BGImg from '../../assets/images/background/work.jpg'
import BGImg1 from '../../assets/images/background/slider-1-1.jpg'
import BGImg2 from '../../assets/images/background/slider-1-2.jpg'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import BGImg4 from '../../assets/images/shapes/funfact-one-bg.png'
import BGImg5 from '../../assets/images/shapes/testi-bg-1-1.png'
import BGImg6 from '../../assets/images/shapes/team-home-bg-1-1.jpg'
import BGImg7 from '../../assets/images/shapes/about-three-s-1.png'
import img1 from '../../assets/images/rsz_bg.jpg'
import img2 from '../../assets/images/thumb_.jpg'
import AnimatedNumber from "animated-number-react"
import { Parallax } from 'react-parallax'
import BG2 from "../../assets/images/shapes/testi-bg-1-1.png";
import Testi from "../../assets/images/resources/testi-1-1.jpg";
import Client1 from "../../assets/images/resources/client-1-1.png";
import Lloyds from "../../assets/images/lloyds.svg";
import Polestar from "../../assets/images/polestar.svg";
import Pyronix from "../../assets/images/pyronix.svg";
import ikea from "../../assets/images/ikea_op.svg";
import resurs from "../../assets/images/resurs.svg";
import lynkco from "../../assets/images/lynkco.svg";
import handlesbanken from "../../assets/images/handlesbanken.svg";
import volvo from "../../assets/images/volvo.svg";
import imgarbage from "../../assets/images/imgarbage.svg";


const Home = () => {

    const [ytShow, setytShow] = useState(false)
    const options = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: false,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 2
            },
            768: {
                margin: 30,
                items: 3
            },
            992: {
                margin: 30,
                items: 4
            },
            1200: {
                margin: 30,
                items: 5
            }
        },
    };
    const sliderOptions = {
        loop: true,
        items: 1,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        margin: 0,
        dots: true,
        nav: true,
        animateOut: "slideOutDown",
        animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }
    const projectOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 0,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            }
        }
    }
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <div className="slider-one">
                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
                    <div className="item slider-one__slide-1">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg1})`, backgroundPosition: 'center center' }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">BEET technologies</div>
                                <p className="slider-one__text">Bringing</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Simple solutions <br />
                                        to complex problems</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item slider-one__slide-2">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg2})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                <p className="slider-one__text">Bringing fun to technologies</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">We take care of you while we fix your problems.</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>

            <section
                className=" section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                style={{ backgroundImage: `url(${BGImg3})` }}>
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">What we do</p>
                        <h2 className="section-title__title">Build amazing digital products</h2>
                        Unlock your development team's full potential, with accelerated delivery that empowers you to ship amazing digital products, faster.
                    </div>
                    <OwlCarousel
                        className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options}
                    >
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-consulting"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/it-consultant">IT Consulting</Link></h3>
                                    <div className="service-card-three__text">Elevating Your Business with Expert IT Solutions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-android"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/mobile-application-development">Mobile App
                                        <br />
                                        Development</Link></h3>
                                    <div className="service-card-three__text">Bringing Your Ideas to Life, One Tap at a Time
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-web-design"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link
                                        to="/software-engineering">Software <br />
                                        Development</Link></h3>
                                    <div className="service-card-three__text">Innovating Tomorrow's Solutions, One Line of Code at a Time
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-system"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/software-engineering">QA
                                        <br />
                                        Testing</Link></h3>
                                    <div className="service-card-three__text">Elevating Quality Assurance with Precision Testing for Seamless Performance
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-cloud-data"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title"><Link to="/software-engineering">Cloud &
                                        <br />
                                        DevOps</Link></h3>
                                    <div className="service-card-three__text">Streamlining Development to Deployment and Bridging the Gap Between Code and Operations
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>

            <section
                className="section-padding--bottom testimonials-two background-repeat-no background-position-top-center"
                style={{backgroundImage: `url(${BG2})`}}>
                <div className="container">
                    <div className="row gutter-y-60 section-padding--top">
                        <div className="col-lg-5">
                            <div className="testimonials-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Our clients</p>
                                    <h2 className="section-title__title">Making a remarkable impact for every client</h2>
                                </div>
                                <div className="testimonials-two__content__text">We’re proud to work with multi-national businesses right through to smaller scale-ups, with a depth and breadth of experience across many industries and markets.
                                </div>
                                <div className="testimonials-two__content__text">Together, with our clients we solve unique and complex problems, engineer solutions, and delight both our clients and their customers - across the UK, Europe, Africa and the USA.
                                </div>
                                <div className="testimonials-two__content__text">We form a different kind of partnership - working as one team for the long-term. Our clients come back to us time and again to help them solve their next complex problem and make another remarkable impact, together.
                                </div>
                                {/*<Link to="/about" className="thm-btn testimonials-two__content__btn"><span>View All*/}
                                {/*    feedbacks</span></Link>*/}
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonials-two__items">
                                <div className="row gutter-y-30">
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image" style={{paddingLeft: "20px"}}>
                                                {/*<img src={Testi} alt=""/>*/}
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">
                                                I recently had the opportunity to work with a BEET Technologies and I must say, I was thoroughly impressed with their services. From their innovative products to their excellent customer support, this company truly stands out in the competitive tech industry.
                                                </div>
                                                <h3 className="testimonials-one-card__title">Brite B</h3>
                                                <p className="testimonials-one-card__designation">Scrum Master - I'm Garbage</p>
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image" style={{paddingLeft: "20px"}}>
                                                {/*<img src={Testi} alt=""/>*/}
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">
                                                I worked with some engineers from BEET Technologies and I must say, it was a truly remarkable experience. From their top-notch professionalism to their exceptional experiences, these guys truly stands out in the competitive world of software development.
                                                </div>
                                                <h3 className="testimonials-one-card__title">Vincent Oke</h3>
                                                <p className="testimonials-one-card__designation">Test Manager - IKEA</p>
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="client-carousel client-carousel--two">
                <div className="container">
                    <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
                        <div className="item">
                            <img src={Lloyds} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={Pyronix} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={imgarbage}  style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={ikea}  style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={Polestar} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={resurs} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={lynkco} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={handlesbanken} style={{width: 122, height: 53}} alt=""/>
                        </div>
                        <div className="item">
                            <img src={volvo} style={{width: 122, height: 63}} alt=""/>
                        </div>
                    </OwlCarousel>
                </div>
            </div>

            {/*<section className="funfact-one">*/}
            {/*    <div className="container">*/}
            {/*        <div className="funfact-one__inner wow fadeInUp background-size-cover" data-wow-duration="1500ms"*/}
            {/*            style={{ backgroundImage: `url(${BGImg4})` }}>*/}
            {/*            <ul className="funfact-one__list">*/}
            {/*                <li className="funfact-one__list__item">*/}
            {/*                    <h3 className="funfact-one__list__title count-box">*/}
            {/*                        <span data-stop="255" data-speed="2500" className="count-text"><AnimatedNumber value={100} duration={2500} formatValue={(v) => Math.round(v)} /></span>*/}
            {/*                    </h3>*/}
            {/*                    <p className="funfact-one__list__text">Business Interrogation</p>*/}
            {/*                </li>*/}
            {/*                <li className="funfact-one__list__item">*/}
            {/*                    <h3 className="funfact-one__list__title count-box">*/}
            {/*                        <span data-stop="325" data-speed="2500" className="count-text"><AnimatedNumber value={50} duration={2500} formatValue={(v) => Math.round(v)} /></span>*/}
            {/*                    </h3>*/}
            {/*                    <p className="funfact-one__list__text">Strategies Planned</p>*/}
            {/*                </li>*/}
            {/*                <li className="funfact-one__list__item">*/}
            {/*                    <h3 className="funfact-one__list__title count-box">*/}
            {/*                        <span data-stop="569" data-speed="2500" className="count-text"><AnimatedNumber value={80} duration={2500} formatValue={(v) => Math.round(v)} /></span>*/}
            {/*                    </h3>*/}
            {/*                    <p className="funfact-one__list__text">Projects Released</p>*/}
            {/*                </li>*/}
            {/*                <li className="funfact-one__list__item">*/}
            {/*                    <h3 className="funfact-one__list__title count-box">*/}
            {/*                        <span data-stop="769" data-speed="2500" className="count-text"><AnimatedNumber value={179} duration={2500} formatValue={(v) => Math.round(v)} /></span>*/}
            {/*                    </h3>*/}
            {/*                    <p className="funfact-one__list__text">Satisfied Clients </p>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {/*<section className="section-padding--bottom section-padding--top project-one">*/}
            {/*    <div className="container">*/}
            {/*        <div className="project-one__top">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-lg-7">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">Latest Project</p>*/}
            {/*                        <h2 className="section-title__title">Check We Have Solution <br />Done IT Project</h2>*/}

            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-lg-5">*/}
            {/*                    <div className="project-one__top__content">*/}
            {/*                        <p className="project-one__top__text">Pellentesque Viverra Eget Velit A Tincidunt. Sed*/}
            {/*                            Mattis Enim Nisl, Sit Amet Malesuada Sapien Pulvinar Ut.</p>*/}
            {/*                        <div className="project-one__top__btns">*/}
            {/*                            <Link to="/projects" className="thm-btn project-one__top__btn">*/}
            {/*                                <span>View All Projects</span>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}

            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <OwlCarousel className="thm-owl__carousel project-one__carousel thm-owl__carousel--with-shadow" {...projectOptions}>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-1.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-2.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-3.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-4.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-5.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <div className="project-card-one">*/}
            {/*                    <div className="project-card-one__image">*/}
            {/*                        <img src="assets/images/projects/project-1-6.jpg" alt="" />*/}
            {/*                    </div>*/}
            {/*                    <div className="project-card-one__content">*/}
            {/*                        <div className="project-card-one__content__inner">*/}
            {/*                            <p className="project-card-one__text">IT Technology Solution</p>*/}
            {/*                            <h3 className="project-card-one__title"><Link to="/project-details">Data Recovery*/}
            {/*                                Analysis</Link></h3>*/}
            {/*                            <Link to="/project-details" className="project-card-one__more">*/}
            {/*                                <i className="fa fa-angle-right"></i>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </OwlCarousel>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {/*<section class="black-bg cta-two">*/}
            {/*    <Parallax bgImage={BGImg} bgImageAlt="" className="section-padding-lg--top section-padding-lg--bottom" strength={1000}>*/}
            {/*        <div className="container">*/}
            {/*            <div className="cta-two__inner">*/}
            {/*                <h3 className="cta-two__title">Sustainable IT Solutions & Services*/}
            {/*                    at your <span>Fingertips</span></h3>*/}
            {/*                <Link to="/contact" className="thm-btn cta-two__btn"><span>Learn More</span></Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Parallax>*/}
            {/*</section>*/}
            
            {/*<section*/}
            {/*    className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"*/}
            {/*    style={{ backgroundImage: `url(${BGImg5})` }}>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row gutter-y-60">*/}
            {/*            <div className="col-lg-5">*/}
            {/*                <div className="testimonials-two__content">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">Our clients</p>*/}
            {/*                        <h2 className="section-title__title">We Are Trusted*/}
            {/*                            Worldwide Peoples</h2>*/}
            {/*                    </div>*/}
            {/*                    <div className="testimonials-two__content__text">Sed ut perspiciatis unde omnis natus error sit*/}
            {/*                        voluptatem accusa ntium doloremque laudantium totam rem aperiamea queipsa quae abillo*/}
            {/*                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>*/}
            {/*                    <div className="testimonials-two__content__text">Pellentesque gravida lectus vitae nisi luctus,*/}
            {/*                        Finibus aliquet ligula ultrices.</div>*/}
            {/*                    <Link to="/about" className="thm-btn testimonials-two__content__btn"><span>View All*/}
            {/*                        feedbacks</span></Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-7">*/}
            {/*                <div className="testimonials-two__items">*/}
            {/*                    <div className="row gutter-y-30">*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src="assets/images/resources/testi-1-1.jpg" alt="" />*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.</div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Michal Rahul</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}

            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src="assets/images/resources/testi-1-2.jpg" alt="" />*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.</div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Jessica Brown</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}
            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<div className="client-carousel client-carousel--two">*/}
            {/*    <div className="container">*/}
            {/*        <OwlCarousel className="thm-owl__carousel" {...logoOptions}>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src="assets/images/resources/client-1-1.png" alt="" />*/}
            {/*            </div>*/}
            {/*        </OwlCarousel>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<section className="section-padding--bottom section-padding--top gray-bg background-size-cover"*/}
            {/*    style={{ backgroundImage: `url(${BGImg6})` }}>*/}
            {/*    <div className="container">*/}
            {/*        <div className="section-title text-center">*/}
            {/*            <p className="section-title__text">Our Executive Team members</p>*/}
            {/*            <h2 className="section-title__title">Our Expert Persons to provide <br /> IT Solution Services</h2>*/}
            {/*        </div>*/}
            {/*        <div className="row gutter-y-30">*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src="assets/images/team/team-2-1.jpg" alt="" />*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Etukeni Ndecha</Link></h3>*/}

            {/*                        <p className="team-card-one__designation">Founder & CTO</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src="assets/images/team/team-2-2.jpg" alt="" />*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Mark James</Link></h3>*/}

            {/*                        <p className="team-card-one__designation">CEO</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src="assets/images/team/team-2-3.jpg" alt="" />*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Kevin Martin</Link></h3>*/}

            {/*                        <p className="team-card-one__designation">Head Of Engineering</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src="assets/images/team/team-2-4.jpg" alt="" />*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Sarah Albert</Link></h3>*/}

            {/*                        <p className="team-card-one__designation">Head Of Human Resources</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {/*<section className=" section-padding--top about-three about-three--home-one">*/}
            {/*    <div className="about-three__shape wow fadeInRight" data-wow-duration="1500ms"*/}
            {/*        style={{ backgroundImage: `url(${BGImg7})` }}>*/}
            {/*    </div>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row gutter-y-60">*/}
            {/*            <div className="col-lg-6">*/}
            {/*                <div className="about-three__image">*/}
            {/*                    <img src="assets/images/resources/about-three-1-1.png" className="wow fadeInUp"*/}
            {/*                        data-wow-duration="1500ms" alt="" />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-6">*/}
            {/*                <div className="about-three__content">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">Company Benefits</p>*/}
            {/*                        <h2 className="section-title__title">Why You Should Choose Our Services</h2>*/}
            {/*                    </div>*/}
            {/*                    <div className="about-three__text">Choose BEET Technologies to have custom software solutions for your*/}
            {/*                        business with the most reasonable price.</div>*/}
            {/*                    <ul className="about-three__list">*/}
            {/*                        <li className="about-three__list__item">*/}
            {/*                            <div className="about-three__list__icon">*/}
            {/*                                <i className="icon-cloud"></i>*/}
            {/*                            </div>*/}
            {/*                            <div className="about-three__list__content">*/}
            {/*                                <h3 className="about-three__list__title"><Link to="cyber-security">Cloud*/}
            {/*                                    Based Services</Link></h3>*/}
            {/*                                <p className="about-three__list__text">Services address a range of simply free text*/}
            {/*                                    application and infrastructure needs.</p>*/}
            {/*                            </div>*/}
            {/*                        </li>*/}
            {/*                        <li className="about-three__list__item">*/}
            {/*                            <div className="about-three__list__icon">*/}
            {/*                                <i className="icon-group"></i>*/}
            {/*                            </div>*/}
            {/*                            <div className="about-three__list__content">*/}
            {/*                                <h3 className="about-three__list__title"><Link to="/team">Expert Team Members</Link>*/}
            {/*                                </h3>*/}
            {/*                                <p className="about-three__list__text">Services address a range of simply free text*/}
            {/*                                    application and infrastructure needs.</p>*/}
            {/*                            </div>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="section-padding--top section-padding--bottom">
                {/*<div className="container">*/}
                {/*    <div className="section-title text-center">*/}
                {/*        <p className="section-title__text">Direct from the Blog Posts</p>*/}
                {/*        <h2 className="section-title__title">Checkout Our Latest <br />*/}
                {/*            News & Articles</h2>*/}
                {/*    </div>*/}
                {/*    <div className="row gutter-y-30">*/}
                {/*        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"*/}
                {/*            data-wow-delay="000ms">*/}
                {/*            <div className="blog-card-one">*/}
                {/*                <div className="blog-card-one__image">*/}
                {/*                    <img src="assets/images/blog/blog-1-1.jpg" alt="" />*/}
                {/*                    <Link to="/blog-details"></Link>*/}
                {/*                </div>*/}
                {/*                <div className="blog-card-one__content">*/}
                {/*                    <div className="blog-card-one__meta">*/}
                {/*                        <div className="blog-card-one__date">*/}
                {/*                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                {/*                            July, 25, 2022*/}
                {/*                        </div>*/}
                {/*                        <Link to="/blog" className="blog-card-one__category">Designer</Link>*/}
                {/*                    </div>*/}
                {/*                    <h3 className="blog-card-one__title"><Link to="/blog-details">Web design done Delightful*/}
                {/*                        Visualization Examples</Link></h3>*/}
                {/*                    <Link to="/blog-details" className="blog-card-one__more">*/}
                {/*                        Read More*/}
                {/*                        <i className="fa fa-arrow-right"></i>*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"*/}
                {/*            data-wow-delay="100ms">*/}
                {/*            <div className="blog-card-one">*/}
                {/*                <div className="blog-card-one__image">*/}
                {/*                    <img src="assets/images/blog/blog-1-2.jpg" alt="" />*/}
                {/*                    <Link to="/blog-details"></Link>*/}
                {/*                </div>*/}
                {/*                <div className="blog-card-one__content">*/}
                {/*                    <div className="blog-card-one__meta">*/}
                {/*                        <div className="blog-card-one__date">*/}
                {/*                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                {/*                            July, 25, 2022*/}
                {/*                        </div>*/}
                {/*                        <Link to="/blog" className="blog-card-one__category">Graphic</Link>*/}
                {/*                    </div>*/}
                {/*                    <h3 className="blog-card-one__title"><Link to="/blog-details">Technology Support Allows*/}
                {/*                        Erie non-profit to Serve</Link></h3>*/}
                {/*                    <Link to="/blog-details" className="blog-card-one__more">*/}
                {/*                        Read More*/}
                {/*                        <i className="fa fa-arrow-right"></i>*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"*/}
                {/*            data-wow-delay="200ms">*/}
                {/*            <div className="blog-card-one">*/}
                {/*                <div className="blog-card-one__image">*/}
                {/*                    <img src="assets/images/blog/blog-1-3.jpg" alt="" />*/}
                {/*                    <Link to="/blog-details"></Link>*/}
                {/*                </div>*/}
                {/*                <div className="blog-card-one__content">*/}
                {/*                    <div className="blog-card-one__meta">*/}
                {/*                        <div className="blog-card-one__date">*/}
                {/*                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>*/}
                {/*                            July, 25, 2022*/}
                {/*                        </div>*/}
                {/*                        <Link to="/blog" className="blog-card-one__category">SEO</Link>*/}
                {/*                    </div>*/}
                {/*                    <h3 className="blog-card-one__title"><Link to="/blog-details">Software Makes Your Profit*/}
                {/*                        Double if You Scale Properly</Link></h3>*/}
                {/*                    <Link to="/blog-details" className="blog-card-one__more">*/}
                {/*                        Read More*/}
                {/*                        <i className="fa fa-arrow-right"></i>*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>

            {/*<section className="cta-one">*/}
            {/*    <div className="container">*/}
            {/*        <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">*/}
            {/*            <div className="cta-one__circle"></div>*/}
            {/*            <div className="section-title ">*/}
            {/*                <p className="section-title__text">Need Any Technology Solution</p>*/}
            {/*                <h2 className="section-title__title section-title__title--light">Let’s Work Together on Project</h2>*/}
            {/*            </div>*/}
            {/*            <Link to="/contact" className="thm-btn thm-btn--light cta-one__btn"><span>Start Now</span></Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        <iframe src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1" title='ytvideo' allowFullScreen></iframe>
                    </div>
                </div>}
        </>
    )
}

export default Home