import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import BG1 from '../../assets/images/background/work.jpg'
import BG2 from '../../assets/images/shapes/testi-bg-1-1.png'
import Breadcumb from '../Breadcumb/Main'
import {Parallax} from 'react-parallax'
import BgImg from "../../assets/images/unity.jpg"
import AB from "../../assets/images/resources/about-four-1-1.jpg"
import Author from "../../assets/images/resources/about-four-author-1.jpg"
import TeamA from "../../assets/images/team/team-2-1.jpg"
import Testi from "../../assets/images/resources/testi-1-1.jpg"
import Client1 from "../../assets/images/resources/client-1-1.png"
import img1 from "../../assets/images/rsz_bg.jpg";
import AnimatedNumber from "animated-number-react";

const About = () => {
    const [ytShow, setytShow] = useState(false)
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <Breadcumb Title="About Us" Breadcumb="ABOUT" BgImg={BgImg}/>

            <section className="section-padding--bottom section-padding--top about-five">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-five__images">
                                <div className="about-five__images__shape"></div>
                                <img src={img1} className="wow fadeInUp"
                                     data-wow-duration="1500ms" alt="" />
                                {/*<img src={img2} alt="" />*/}
                                {/*<div className="about-five__video">*/}
                                {/*    <Link to="#" onClick={() => setytShow(true)} className="video-popup">*/}
                                {/*        <i className="fa fa-play"></i>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">*/}
                                {/*    <span className="count-text"><AnimatedNumber value={15} duration={2500} formatValue={(v) => Math.round(v)} /></span>*/}
                                {/*    Years <br />*/}
                                {/*    Experience*/}
                                {/*</div>*/}
                            </div>
                            <div className="about-five__content " style={{marginTop: "20px"}}>
                                <div className="about-five__content">
                                    <div className="section-title ">
                                        <h2 className="section-title__title">Our priority</h2>
                                    </div>

                                    <div className="about-five__text">
                                        At BEET Technologies, your success is our top priority. Whether you need end-to-end software development services or require assistance with specific stages of the development lifecycle, we're here to help you navigate the digital landscape and achieve your goals.

                                        <br/>
                                        <br/>

                                        Explore our website to learn more about our services, expertise, and success stories, and discover how we can help you harness the power of technology to propel your business forward.
                                        Welcome to the future of software development with BEET Technologies.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Our Company</p>
                                    <h2 className="section-title__title">The Pride of Engineering Hub in UK</h2>
                                </div>
                                <div className="about-five__text">

                                    Welcome to BEET Technologies, your trusted partner in software innovation.
                                    As a premier software development company, we specialize in crafting cutting-edge solutions that drive growth, efficiency, and success for businesses worldwide.

                                    <br/>
                                    <br/>
                                    At BEET Technologies, we believe in the transformative power of technology. Our dedicated team of developers, designers, and engineers is committed to delivering bespoke software solutions tailored to your unique needs and objectives. Whether you're a startup looking to disrupt the market or an established enterprise seeking to optimize operations, we have the expertise and experience to bring your vision to life.

                                    <br/>
                                    <br/>
                                    With a focus on innovation and quality, we leverage the latest technologies and best practices to create scalable, robust, and user-friendly software applications. From web and mobile apps to enterprise solutions and custom integrations, we deliver results that exceed expectations and drive measurable impact.

                                    <br/>
                                    <br/>
                                    Our collaborative approach ensures that we understand your business goals and challenges, allowing us to develop solutions that align with your strategic objectives. We prioritize transparency, communication, and accountability throughout the development process, keeping you informed and involved every step of the way.
                                </div>
                                {/*<div className="about-five__text">*/}
                                {/*    System is a term used to refer to an organized collection*/}
                                {/*    symbols and processes that may be used to operate on such symbols.*/}
                                {/*</div>*/}
                                {/*<ul className="about-five__box">*/}
                                {/*    <li className="about-five__box__item gray-bg">*/}
                                {/*        <i className="about-five__box__icon icon-consulting"></i>*/}
                                {/*        <div className="about-five__box__content">*/}
                                {/*            <h3 className="about-five__box__title"><Link to="/it-consultent">IT*/}
                                {/*                Consultant</Link></h3>*/}
                                {/*            <p className="about-five__box__text">Smarter Solutions</p>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}
                                {/*    <li className="about-five__box__item gray-bg">*/}
                                {/*        <i className="about-five__box__icon icon-chip"></i>*/}
                                {/*        <div className="about-five__box__content">*/}
                                {/*            <h3 className="about-five__box__title"><Link to="/team">IT Specialist</Link></h3>*/}
                                {/*            <p className="about-five__box__text">Faster Solutions</p>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                                {/*<div className="about-four__meta">*/}
                                {/*    <div className="about-four__author">*/}
                                {/*        <img src="assets/images/resources/about-four-author-1.jpg" alt="" />*/}
                                {/*        <div className="about-four__author__content">*/}
                                {/*            <h3 className="about-four__author__title">Etukeni Ndecha</h3>*/}
                                {/*            <div className="about-four__author__designation">Founder & CEO</div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <Link to="/service-01" className="thm-btn about-four__btn"><span>Learn More</span></Link>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="about-four section-padding--top">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row gutter-y-60">*/}
            {/*            <div className="col-lg-6">*/}
            {/*                <div className="about-four__image">*/}
            {/*                    <img src={AB} className="wow fadeInUp"*/}
            {/*                         data-wow-duration="1500ms" alt=""/>*/}
            {/*                </div>*/}
            {/*                <div className="about-four__content">*/}
            {/*                    <div className="section-title section-padding--top">*/}
            {/*                        <h2 className="section-title__title">Who we are</h2>*/}
            {/*                    </div>*/}
            {/*                    <div className="about-four__text">*/}
            {/*                        We started in 2017 and are today in Malmö (Sweden), Birmingham (UK), Accra (Ghana).*/}
            {/*                        You can find our customers all over the Europe and Africa. Together, we create*/}
            {/*                        benefit-oriented and long-term values.*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        In our team we welcome good professionals who are interested in constant self*/}
            {/*                        improving and are*/}
            {/*                        always ready to pass on their knowledge.*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        Most people encounter BEET Technologies several times during a typical day, from*/}
            {/*                        morning to night. We exist in everyday life, imperceptibly in the middle of the*/}
            {/*                        step, when we help make life work. Like when you manage the car's functions via an*/}
            {/*                        app, buy tickets online or shop for food - both in store and online. Then we are*/}
            {/*                        there. We are also there when you use your smartphone, or book a session at the gym.*/}
            {/*                        And much more. In short: You find our fingerprints in large parts of everyday life*/}
            {/*                        for many people.*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-6">*/}
            {/*                <div className="about-four__content">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">About BEET Technologies</p>*/}
            {/*                        <h2 className="section-title__title">Who we are</h2>*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        We started in 2017 and are today in Malmö (Sweden), Birmingham (UK), Accra (Ghana).*/}
            {/*                        You can find our customers all over the Europe and Africa. Together, we create*/}
            {/*                        benefit-oriented and long-term values.*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        In our team we welcome good professionals who are interested in constant self*/}
            {/*                        improving and are*/}
            {/*                        always ready to pass on their knowledge.*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        Most people encounter BEET Technologies several times during a typical day, from*/}
            {/*                        morning to night. We exist in everyday life, imperceptibly in the middle of the*/}
            {/*                        step, when we help make life work. Like when you manage the car's functions via an*/}
            {/*                        app, buy tickets online or shop for food - both in store and online. Then we are*/}
            {/*                        there. We are also there when you use your smartphone, or book a session at the gym.*/}
            {/*                        And much more. In short: You find our fingerprints in large parts of everyday life*/}
            {/*                        for many people.*/}
            {/*                    </div>*/}

            {/*                    <div className="about-four__text">*/}
            {/*                        We succeed in this because we offer a wide range of IT skills for a wide range of*/}
            {/*                        customers. But also because of the way we look at IT solutions. Not as ones and*/}
            {/*                        zeros, but as the result of a human action aimed at making life easier and better*/}
            {/*                        for other people. And that in turn affects how one needs to approach the task; keep*/}
            {/*                        it simple, be straightforward, fast and transparent.*/}
            {/*                    </div>*/}
            {/*                    /!*<div className="about-four__meta">*!/*/}
            {/*                    /!*    <div className="about-four__author">*!/*/}
            {/*                    /!*        <img src={Author} alt=""/>*!/*/}
            {/*                    /!*        <div className="about-four__author__content">*!/*/}
            {/*                    /!*            <h3 className="about-four__author__title">Etukeni Ndecha</h3>*!/*/}
            {/*                    /!*            <div className="about-four__author__designation">Founder & CEO</div>*!/*/}
            {/*                    /!*        </div>*!/*/}
            {/*                    /!*    </div>*!/*/}
            {/*                    /!*    <Link to="/service-01" className="thm-btn about-four__btn"><span>Learn More</span></Link>*!/*/}
            {/*                    /!*</div>*!/*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className="section-padding--bottom section-padding--top">*/}
            {/*    <div className="container">*/}
            {/*        <div className="section-title text-center">*/}
            {/*            <p className="section-title__text">Our Team members</p>*/}
            {/*            <h2 className="section-title__title">Meet Our Experts</h2>*/}

            {/*        </div>*/}
            {/*        <div className="row gutter-y-30">*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="000ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src={img1} alt=""/>*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Etukeni Ndecha</Link></h3>*/}
            {/*                        <p className="team-card-one__designation">Founder & CTO</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="100ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src={TeamA} alt=""/>*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Tua Nadesh</Link></h3>*/}
            {/*                        <p className="team-card-one__designation">Senior Scrum Master</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="200ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src={TeamA} alt=""/>*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Etukeni Justin</Link></h3>*/}
            {/*                        <p className="team-card-one__designation">Android Engineer - Intern</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-3 col-md-6 col-sm-12">*/}
            {/*                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="300ms">*/}
            {/*                    <div className="team-card-one__image">*/}
            {/*                        <img src={TeamA} alt=""/>*/}
            {/*                        <div className="team-card-one__social">*/}
            {/*                            <ul className="team-card-one__social__links">*/}
            {/*                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>*/}
            {/*                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>*/}
            {/*                            </ul>*/}
            {/*                            <div className="team-card-one__social__icon">*/}
            {/*                                <i className="fa fa-share-alt"></i>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="team-card-one__content">*/}
            {/*                        <h3 className="team-card-one__title"><Link to="#">Sarah Albert</Link></h3>*/}
            {/*                        <p className="team-card-one__designation">Director</p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className="black-bg cta-two">*/}
            {/*    <Parallax bgImage={BG1} bgImageAlt=""*/}
            {/*              className="cta-two__bg section-padding-lg--top section-padding-lg--bottom" strength={300}>*/}
            {/*        <div className="container">*/}
            {/*            <div className="cta-two__inner">*/}
            {/*                <h3 className="cta-two__title">Better IT Solutions & Services*/}
            {/*                    at your <span>Fingertips</span></h3>*/}
            {/*                <Link to="/contact" className="thm-btn cta-two__btn"><span>LEarn More</span></Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Parallax>*/}
            {/*</section>*/}
            {/*<section*/}
            {/*    className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"*/}
            {/*    style={{backgroundImage: `url(${BG2})`}}>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row gutter-y-60">*/}
            {/*            <div className="col-lg-5">*/}
            {/*                <div className="testimonials-two__content">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">Our clients</p>*/}
            {/*                        <h2 className="section-title__title">We Are Trusted*/}
            {/*                            Worldwide Peoples</h2>*/}
            {/*                    </div>*/}
            {/*                    <div className="testimonials-two__content__text">Sed ut perspiciatis unde omnis natus*/}
            {/*                        error sit*/}
            {/*                        voluptatem accusa ntium doloremque laudantium totam rem aperiamea queipsa quae*/}
            {/*                        abillo*/}
            {/*                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.*/}
            {/*                    </div>*/}
            {/*                    <div className="testimonials-two__content__text">Pellentesque gravida lectus vitae nisi*/}
            {/*                        luctus,*/}
            {/*                        Finibus aliquet ligula ultrices.*/}
            {/*                    </div>*/}
            {/*                    <Link to="/about" className="thm-btn testimonials-two__content__btn"><span>View All*/}
            {/*                        feedbacks</span></Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-7">*/}
            {/*                <div className="testimonials-two__items">*/}
            {/*                    <div className="row gutter-y-30">*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src={Testi} alt=""/>*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc*/}
            {/*                                        with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the*/}
            {/*                                        charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.*/}
            {/*                                    </div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Michal Rahul</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}
            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src={Testi} alt=""/>*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc*/}
            {/*                                        with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the*/}
            {/*                                        charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.*/}
            {/*                                    </div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Jessica Brown</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}
            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<div className="client-carousel client-carousel--two">*/}
            {/*    <div className="container">*/}
            {/*        <OwlCarousel className="thm-owl__carousel" {...logoOptions}>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*            <div className="item">*/}
            {/*                <img src={Client1} alt=""/>*/}
            {/*            </div>*/}
            {/*        </OwlCarousel>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default About