import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import BgImg from "../../../assets/images/agile.jpg";
import consulting from "../../../assets/images/consulting.jpg";

const ITConsultant = () => {
	const [clicked, setClicked] = useState(0);
	const faqs = [
		{
			question: "Governance",
			answer: "We’ll help you test and implement the right delivery frameworks, enabling you to manage and coordinate the development of complex products at pace. We’ll ensure your delivery is aligned with your organisational goals and that your teams are working effectively."
		},
		{
			question: "Tech leadership",
			answer: "We’ll ensure that you’re working with the right technology and in a way that’s enabled by the right principles and governance. We’ll also equip your people with the skills they need to guide your teams to success through effective decision-making, mentoring and coaching others, and fostering a culture of continuous learning and innovation."
		},
		{
			question: "Agile coaching",
			answer: "Our expert Agile coaches will support your teams in successfully implementing Agile methodologies - Scrum, Kanban, LeSS, and Nexus. This will enable you to work iteratively, enhancing your collaboration and adaptability."
		},
		{
			question: "Scrum Masters",
			answer: "Our experienced Scrum Masters will help you to effectively implement and adhere to Scrum, enabling your project to run effectively. They’ll facilitate improved team communication, help your team to self-organise, remove blockers, and enable efficient product delivery through focussed sprints."
		},
		{
			question: "Exec level delivery",
			answer: "We’ll coach your c-suite so that they understand the full organisational value of effective delivery. This will enable a culture where agility, experimentation, and continual learning are prized. Resulting in high-value, regular releases that deliver better outcomes for your developers and your customers.",
		}
	]
	return (
		<>
			<Breadcumb Title="IT Consultanting" Breadcumb="SERVICES" BgImg={BgImg}/>
			<section className="section-padding--bottom section-padding--top service-details--page">
				<div className="container">
					<div className="row ">
						<div className="col-lg-8">
							<div className="service-details__image">
								<img src={consulting} alt="" />
							</div>
							<h3 className="service-details__title">Consulting</h3>
							<div className="service-details__content">
								<p>Understanding your organisation’s core capabilities is the key to digital greatness. That’s why we offer expertise in strategy, tech, product, people, data, and delivery. We help you assess, define, and grow your ability to deliver value for your customers.</p>
								<h4>What is consulting?</h4>
								<p>
									Our dedicated consultants will guide your strategy, enabling our Clubs to work with you to build remarkable products and platforms. We’ll help you understand your digital maturity and capability gaps. We’ll clarify and articulate your strategic direction to give you a clear North Star. Then agree and design meaningful changes to your digital delivery. From there we’ll support and manage that delivery to realise
									value for your organisation and customers, faster. Simply put, we translate strategy into execution to solve your complex problems at scale.
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<ServiceSidebar />
						</div>
					</div>
					<div className="row ">
						<div className="col-lg-12">
							<div className="service-details__content">
								<h4>The problems we solve</h4>
							</div>

							<div className="row gutter-y-30 service-details__box-wrapper">
								<div className="col-md-6 col-sm-12">
									<div className="service-details__box">
										{/*<i className="service-details__box__icon icon-consulting"></i>*/}
										<div className="service-details__box__content">
											<h3 className="service-details__box__title">
												<Link to="#">Delivery</Link>
											</h3>
											<p className="service-details__box__text">
												Improve the pace and quality of your delivery - working across your products,
												your people, and your technology to accelerate your time to value and team engagement.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="service-details__box">
										{/*<i className="service-details__box__icon icon-chip"></i>*/}
										<div className="service-details__box__content">
											<h3 className="service-details__box__title">
												<Link to="#">Propositions</Link>
											</h3>
											<p className="service-details__box__text">
												Identify, validate and launch new customer propositions. Enabling you to take remarkable new products and services to market quickly, increasing your revenue and driving your business forward.
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="row gutter-y-30 service-details__box-wrapper">
								<div className="col-md-6 col-sm-12">
									<div className="service-details__box">
										{/*<i className="service-details__box__icon icon-consulting"></i>*/}
										<div className="service-details__box__content">

											<h3 className="service-details__box__title">
												<Link to="#">Efficiency
												</Link>
											</h3>
											<p className="service-details__box__text">
												Analyse your existing tools, talent, and ways of working to identify what’s working well, and what can be improved and how. This will allow you to unlock both operational efficiencies as well as additional value.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="service-details__box">
										{/*<i className="service-details__box__icon icon-chip"></i>*/}
										<div className="service-details__box__content">
											<h3 className="service-details__box__title">
												<Link to="#">Data</Link>
											</h3>
											<p className="service-details__box__text">Unlock the power of your existing data, and become equipped with the data-driven culture and ways of working you’ll need to harness your data, to make a remarkable impact.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row gutter-y-30">
								{/*<div className="col-md-6 col-sm-12">*/}
								{/*	<img src="assets/images/services/service-d-2.jpg" className="service-details__sub-image"*/}
								{/*		alt="" />*/}
								{/*</div>*/}
								<div className="col-md-6 col-sm-12">
									<div className="service-details__caption">
										<h3 className="service-details__caption__title">How we deliver</h3>
										<p className="service-details__caption__text">Discover how our approach to delivery will enable to you to solve complex problems, at pace.</p>
										<h3 className="service-details__caption__title">The importance of effective delivery</h3>

										<p>
											Having the right digital products isn’t enough, you also need to deliver value to your customers at pace.
											That’s why we help you to define what to release, taking into account the tech and skills you’ll need to do so.
											Our approach is responsible and considered, and we work in a way that is mindful of your budgets and interdependencies.
										</p>
										<p>
											We’ll help you develop a framework for timely, efficient, high-quality product releases - so that you stay competitive and achieve your goals.
											In tandem, our approach to product management will enable you to imagine, develop, and optimise digital products that will delight your customers, and deliver remarkable experiences at pace.
										</p>

										<h3 className="service-details__caption__title">How we approach delivery</h3>
										<p>
											Through our proven approach to delivery, we'll help you to accelerate value for your customers and for your organisation.
										</p>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="accrodion-grp service-details__accrodion"
										data-grp-name="service-details__accrodion-1">
										{faqs.map((item, index) => (
											<div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
												<div className="accrodion-title">
													<h4 onClick={() => setClicked(index)}>{item.question}<span
														className="accrodion-icon"></span>
													</h4>
												</div>
												{index === clicked &&
													<div className="accrodion-content">
														<div className="inner">
															<p>{item.answer}</p>
														</div>
													</div>}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>)
}

export default ITConsultant