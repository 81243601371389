import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import agile from "../../../assets/images/agile.jpg"

const ITManagement = () => {
  const [clicked, setClicked] = useState(0);
  const faqs = [
    {
      question: "Nulla eu nisi pellentesque, ultrices lorem eget, mattis dolo",
      answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
    },
    {
      question: "Praesent nec ante sed libero tempus rutrum",
      answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
    },
    {
      question: "Integer et mi in eros commodo bibendum",
      answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
    },
    {
      question: "Proin commodo turpis eu leo tempus varius",
      answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae estaliquet pellentesque vitae."
    }
  ]
  return (
    <>
      <Breadcumb Title="IT Management" Breadcumb="SERVICES" />
      <section className="section-padding--bottom section-padding--top service-details--page">
        <div className="container">
          <div className="row ">
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={agile} alt="" />
              </div>
              <h3 className="service-details__title">IT management</h3>
              <div className="service-details__content">
                <p>In this delivery, we help our customers realize their digital strategies. Our management consultants have extensive experience in starting, running and implementing solutions that deliver what you actually want to get out.</p>

                <p>We have a great ability to position ourselves exactly where you want to achieve change - and change. In this way, we help you with your digital move or change and concretize and bring to life solutions that both you and your customers can use.</p>

                <p>Here, our consultants' communication skills play a big role. Realizing strategies is based on collaboration, that one is free in thought and actively both listens and conveys – and challenges in the right places. We work together with our customers, not for them.</p>

                <h4>Establishment and implementation of IT strategy</h4>

                <p>Our consultants help you in the work of designing your IT strategy based on where you are. We are quick to familiarize ourselves with and understand your value streams and contribute with experience and knowledge in translating this into delivery activities.</p>

                <ul className="service-details__caption__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Modernizing IT architecture
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Process improvements through IT support
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Modernizing IT architecture
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Procurement and implementation of new systems
                  </li>
                </ul>

                <div className="service-details__content">

                </div>
                <p>Consulting roles: Enterprise Architect, Program Manager, Project Manager, Business Analyst</p>
                <h4>Establishment and implementation of Business Initiatives</h4>

                <p>
                  Our communicative consultants help you grow your business by turning business initiatives into reality that meet your customers or employees. They are used to managing stakeholders at all levels within your company and they often lead projects or teams to deliver what creates value internally and for your customers.
                </p>

                <ul className="service-details__caption__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Establishment and follow-up of business initiatives
                  </li>

                  <li>
                    <i className="fa fa-check-circle"></i>
                    Implementation and delivery of business initiatives
                  </li>

                  <li>
                    <i className="fa fa-check-circle"></i>
                    Quality assurance
                  </li>

                  <li>
                    <i className="fa fa-check-circle"></i>
                    Method improvements Way of Working
                  </li>
                </ul>

                <div className="service-details__content">

                </div>
                <p>
                  Consultant roles: Program Manager, Project Manager, Product Owner, Business Analyst, Scrum Master, Agile Coach, Test Manager, Release Manager, BI Analyst
                </p>
              </div>

              <div className="row gutter-y-30 service-details__box-wrapper">
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-consulting"></i>
                    <div className="service-details__box__content">

                      <h3 className="service-details__box__title">
                        <Link to="#">IT Consultant</Link>
                      </h3>
                      <p className="service-details__box__text">There are many of of lorem Ipsum, but the
                        majori have suffered.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__box">
                    <i className="service-details__box__icon icon-chip"></i>
                    <div className="service-details__box__content">
                      <h3 className="service-details__box__title">
                        <Link to="#">IT Specialist</Link>
                      </h3>
                      <p className="service-details__box__text">There are many of of lorem Ipsum, but the
                        majori have suffered.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gutter-y-30">
                <div className="col-md-6 col-sm-12">
                  <img src="assets/images/services/service-d-2.jpg" className="service-details__sub-image"
                    alt="" />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="service-details__caption">
                    <h3 className="service-details__caption__title">Our Benefits</h3>
                    <p className="service-details__caption__text">Duis aute irure dolor in reprehenderit in
                      voluptate velit esse cillum.</p>
                    <ul className="service-details__caption__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Praesent efficitur quam sit amet
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Nunc cursus dolor id purus euismod
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Quisque tincidunt eros ac place viverra
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="accrodion-grp service-details__accrodion"
                    data-grp-name="service-details__accrodion-1">
                    {faqs.map((item, index) => (
                      <div className={`accrodion wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                        <div className="accrodion-title">
                          <h4 onClick={() => setClicked(index)}>{item.question}<span
                            className="accrodion-icon"></span>
                          </h4>
                        </div>
                        {index === clicked &&
                          <div className="accrodion-content">
                            <div className="inner">
                              <p>{item.answer}</p>
                            </div>
                          </div>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar-column">
              <ServiceSidebar />
            </div>
          </div>
        </div>
      </section>
    </>)
}

export default ITManagement