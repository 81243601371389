import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.svg'
import WOW from 'wowjs'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    const [search, setSearch] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)

    const [menu, setMenu] = useState({})

    const activeMenu = () => {
        if (path === "/") {
            setMenu({home: true})
        } else if (path === "/about" || path === "/team") {
            setMenu({pages: true})
        } else if (path === "/services" || path === "qa-testing" || path === "/it-management" || path === "/it-consultant" || path === "/mobile-application-development" || path === "/software-engineering" || path === "/devops") {
            setMenu({services: true})
        } else if (path === "/careers" || path === "/job-details") {
            setMenu({project: true})
        } else if (path === "/blog" || path === "/blog-details") {
            setMenu({blog: true})
        } else if (path === "/contact") {
            setMenu({contact: true})
        } else {
            setMenu({home: true})
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(()=>{
        window.scroll(0, 0)
        new WOW.WOW({
          live: false
        }).init();
        activeMenu()
    }, [path])
    
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Powered by BEET Technologies Ltd.</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:info@beettechnologies.co.uk">info@beettechnologies.co.uk</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            60 Barr Street, B19 3DX - Birmingham
                        </li>
                    </ul>
                    <ul className="topbar__social">
                        <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} width="98" height="33" alt="BEET Technologies" />
                        </Link>
                    </div>

                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <Link to="/about">About us</Link>
                            {/*<ul>*/}
                            {/*    <li><Link to="/about">About Us</Link></li>*/}
                            {/*    <li><Link to="/team">Our Team</Link></li>*/}
                            {/*</ul>*/}
                        </li>
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="/services">Services</Link>
                            <ul>
                                <li><Link to="/it-consultant">IT Consultant</Link></li>
                                <li><Link to="/mobile-application-development">Mobile App Development</Link></li>
                                <li><Link to="/software-engineering">Software Engineering</Link></li>
                                {/*<li><Link to="/devops">DevOps</Link></li>*/}
                                {/*<li><Link to="/qa-testing">QA & Testing</Link></li>*/}
                            </ul>
                        </li>

                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/contact">Contact</Link>
                        </li>
                        {/*<li className={`menu-item-has-children ${menu.blog && "current"}`}>*/}
                        {/*    <Link to="/projects">Projects</Link>*/}
                        {/*    <ul>*/}
                        {/*        <li><Link to="/it-consultant">Secure Service Gateway(SSG)</Link></li>*/}
                        {/*        <li><Link to="/mobile-application-development">BEET ID</Link></li>*/}
                        {/*        <li><Link to="/mobile-application-development">VeteranApp</Link></li>*/}
                        {/*        <li><Link to="/software-development">Assembly</Link></li>*/}
                        {/*        <li><Link to="/devops">ChurchApp</Link></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li className={`menu-item-has-children ${menu.blog && "current"}`}>*/}
                        {/*    <Link to="/blog">Blog</Link>*/}
                        {/*</li>*/}

                        <li className={`menu-item-has-children ${menu.blog && "current"}`}>
                            <Link to="/careers">Careers</Link>
                            {/*<ul>*/}
                            {/*    <li><Link to="/it-consultant">Graduate & Junior Careers</Link></li>*/}
                            {/*    <li><Link to="/mobile-application-development">Working at BEET Technologies</Link></li>*/}
                            {/*    <li><Link to="/mobile-application-development">Culture</Link></li>*/}
                            {/*    <li><Link to="/software-development">Benefits</Link></li>*/}
                            {/*</ul>*/}
                        </li>
                    </ul>

                    <div className="main-menu__right">
                        <Link to="#" onClick={()=>setMobileMenu(true)} className="mobile-nav__toggler">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                        {/*<Link to="#" onClick={()=>setSearch(true)} className="search-toggler">*/}
                        {/*    <i className="icon-magnifying-glass"></i>*/}
                        {/*</Link>*/}
                        <Link to="tel:+9288009860" className="main-menu__cta">
                            <i className="fa fa-phone-alt"></i>
                            <span className="main-menu__cta__text">
                                <b>+ 44 ( 7586 ) 746998</b>
                                Call Anytime
                            </span>
                        </Link>
                    </div>

                </div>
            </nav>
            {/*<div className={`search-popup ${search && "active"}`}>*/}
            {/*    <div className="search-popup__overlay search-toggler" onClick={()=>setSearch(false)}></div>*/}
            {/*    <div className="search-popup__content">*/}
            {/*        <form action="#">*/}
            {/*            <label htmlFor="search" className="sr-only">search here</label>*/}
            {/*            <input type="text" id="search" placeholder="Search Here..." />*/}
            {/*            <button type="submit" aria-label="search submit" className="thm-btn">*/}
            {/*                <span><i className="icon-magnifying-glass"></i></span>*/}
            {/*            </button>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={`search-popup ${mobileMenu && "active"}`}>
                <div className="search-popup__overlay search-toggler" onClick={()=>setMobileMenu(false)}></div>
                <div className="search-popup__content" style={{color: "#fff"}}>
                <div class="topnav">
                <ul id="menu" >
                        <li>
                            <Link to="/" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Home</Link>
                        </li>
                        <li>
                            <Link to="/about" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>About us</Link>
                        </li>
                        <li>
                            <Link to="/services" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Services</Link>
                            <ul>
                                <li><Link to="/it-consultant" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>IT Consultant</Link></li>
                                <li><Link to="/mobile-application-development" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Mobile App Development</Link></li>
                                <li><Link to="/software-engineering" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Software Engineering</Link></li>
                                {/*<li><Link to="/devops" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>DevOps</Link></li>*/}
                                {/*<li><Link to="/qa-testing" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>QA & Testing</Link></li>*/}
                            </ul>
                        </li>
                        <li>
                            <Link to="/careers" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Careers</Link>
                        </li>
                        <li>
                            <Link to="/contact" style={{color: "#fff"}} onClick={()=>setMobileMenu(false)}>Contact</Link>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </>
    )
}

export default Navbar